import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '43, 7, 59',
		'primary-dark': '22, 4, 30',
		'accent': '183, 18, 255',
		'accent-plus': '255, 255, 255',
	},
});
